import {
	AddIcon,
	Box,
	Button,
	Grid,
	HourglassTopIcon,
	Stack,
	Typography,
} from "@enerbit/base";
import { Link } from "react-router-dom";
import { useJWTStore } from "../../store";

export const HeaderUser = () => {
	const { decodedToken } = useJWTStore();

	return (
		<Grid item xs={12}>
			<Box sx={{ display: "flex", justifyContent: "space-between" }}>
				<Typography
					color="primary"
					sx={{ fontSize: "28px", fontWeight: 700, mb: "12px" }}
				>
					Usuarios
				</Typography>
				<Stack spacing={3} direction={"row"}>
					<Link
						to="/users-companies/create-user"
						style={{ textDecoration: "none" }}
					>
						<Button
							color="secondary"
							variant="contained"
							startIcon={<AddIcon />}
							id="create-user"
						>
							Crear usuario
						</Button>
					</Link>
					<Link to="/crono" style={{ textDecoration: "none" }}>
						<Button
							color="primary"
							variant="contained"
							startIcon={<HourglassTopIcon />}
						>
							Crono
						</Button>
					</Link>
				</Stack>
			</Box>
		</Grid>
	);
};
