import { Grid, LoadingButton, DialogContent, useSnackbar } from "@enerbit/base";
import { FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormItem } from "../components/dynamic-form";
import { IPostCompany } from "../interfaces";
import { formCompany } from "../form-defaults";
import { useCompanyStore } from "../store";
import { zodResolver } from "@hookform/resolvers/zod";
import { PostCompanySchema } from "../schemas";
import { StyledDialog, StyledDialogTitle } from "../common";

interface Props {
	open: boolean;
	handleClose: () => void;
}

export const EditCompany: FC<Props> = ({ open, handleClose }) => {
	const [isLoading, setIsLoading] = useState(false);

	const { enqueueSnackbar } = useSnackbar();

	const { editCompany, company, setCompany } = useCompanyStore(
		(state) => state,
	);

	const methods = useForm<IPostCompany>({
		defaultValues: company,
		resolver: zodResolver(PostCompanySchema),
		mode: "onChange",
	});

	const { handleSubmit, watch } = methods;

	const onSubmit = async (data: IPostCompany) => {
		try {
			if (company) {
				setIsLoading(true);
				await editCompany(company?.id, data);
				setCompany({ ...company, ...watch() });
				handleClose();
				enqueueSnackbar("Empresa editada con éxito.", { variant: "success" });
			}
		} catch (error) {
			enqueueSnackbar((error as Error).message, { variant: "error" });
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<StyledDialog open={open} aria-labelledby="form-dialog-title">
			<StyledDialogTitle id="form-dialog-title" onClose={handleClose}>
				Editar Empresa
			</StyledDialogTitle>
			<DialogContent>
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Grid container spacing={3}>
							{formCompany.map((formField, index) => {
								return <FormItem {...formField} key={index} />;
							})}
							<Grid item xs={12}>
								<LoadingButton
									loading={isLoading}
									id="edit-company"
									type="submit"
									variant="contained"
									color="secondary"
								>
									Editar empresa
								</LoadingButton>
							</Grid>
						</Grid>
					</form>
				</FormProvider>
			</DialogContent>
		</StyledDialog>
	);
};
