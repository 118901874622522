import {
	useSnackbar,
	Grid,
	Box,
	Typography,
	enerbitColors,
	Button,
	LinearProgress,
	Stack,
	RemoveRedEyeOutlinedIcon,
	CachedIcon,
} from "@enerbit/base";
import { FC, useCallback, useEffect, useState } from "react";
import { get, useFormContext } from "react-hook-form";
import { IDynamicForm } from "../../../interfaces";
import { loadFile, uploadFile } from "../../../services";
import Dropzone, { useDropzone } from "react-dropzone";

export const FileForm: FC<IDynamicForm> = ({ name, label, props }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [progress, setProgress] = useState<number>(0);

	const { enqueueSnackbar } = useSnackbar();
	const {
		setValue,
		formState: { errors },
		watch,
		setError,
		clearErrors,
	} = useFormContext();

	const currentValue = watch(name!);

	const handleOpenPreviewFile = async () => {
		await loadFile(watch(name!))
			.then((pdf) => {
				window.open(pdf, "_blank");
			})
			.catch(() =>
				enqueueSnackbar("No se pudo cargar el archivo PDF.", {
					variant: "error",
				}),
			);
	};

	const handleFileChange = async (file: File) => {
		if (file) {
			try {
				setError(name!, { type: "manual", message: "Cargando archivo..." }); // Establecer error de carga
				setIsLoading(true);
				setProgress(0);
				const imageUrl = await uploadFile(file, (event: ProgressEvent) => {
					const percentCompleted = Math.round(
						(event.loaded * 100) / event.total,
					);
					setProgress(percentCompleted);
				});
				setValue(name!, imageUrl);
			} catch (error) {
				setProgress(0);
				setValue(name!, "");
				console.error("Error uploading image:", error);
			} finally {
				clearErrors(name!);
				setIsLoading(false);
			}
		}
	};

	const onDrop = useCallback(
		(acceptedFiles: File[]) => {
			handleFileChange(acceptedFiles[0]);
		},
		[handleFileChange],
	);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: {
			"application/pdf": [],
		},
	});

	useEffect(() => {
		if (name && !currentValue) {
			setValue(name, "");
		}
	}, [currentValue]);

	return (
		<Grid item xs={12}>
			{watch(name!) || (!watch(name!) && isLoading) ? (
				<Stack spacing={3} direction={"row"} sx={{ width: "100%" }} id={name!}>
					<Box className="pdf">PDF</Box>
					<Box sx={{ flex: 1 }}>
						<Typography
							sx={{
								fontSize: "18px",
								fontWeight: "bold",
								color: "#14161B",
							}}
						>
							{label}
						</Typography>
						{isLoading && (
							<LinearProgress variant="determinate" value={progress} />
						)}
					</Box>
					<Stack direction={"row"} spacing={3} padding={1}>
						{!!watch(name!) && (
							<>
								<Button
									variant="contained"
									startIcon={<RemoveRedEyeOutlinedIcon />}
									className="see-button"
									id={`${name}-see-document`}
									onClick={handleOpenPreviewFile}
								>
									Ver documento
								</Button>
								<Dropzone
									disabled={props?.disabled}
									multiple={false}
									accept={{
										"application/pdf": [".pdf"],
									}}
									onDrop={(acceptedFiles: any[]) =>
										handleFileChange(acceptedFiles[0])
									}
								>
									{({ getRootProps, getInputProps }) => (
										<Box>
											<div {...getRootProps()}>
												<input {...getInputProps()} id={`update-${name}`} />
												<Button
													disabled={props?.disabled}
													variant="contained"
													startIcon={<CachedIcon />}
													color="secondary"
												>
													Actualizar
												</Button>
											</div>
										</Box>
									)}
								</Dropzone>
							</>
						)}
					</Stack>
				</Stack>
			) : (
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<div
							{...(getRootProps() as React.HTMLProps<HTMLInputElement>)}
							className="dropzone"
							style={{
								border: `2px dashed ${
									!!get(errors, name) ? enerbitColors.error.main : "#cccccc"
								}`,
								borderRadius: "12px",
								padding: "20px",
								textAlign: "center",
								cursor: "pointer",
								backgroundColor: isDragActive ? "#eeeeee" : "",
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "center",
								gap: "10px",
							}}
						>
							<input
								{...(getInputProps() as React.HTMLProps<HTMLInputElement>)}
								id={`upload-${name}`}
							/>
							<Typography>
								{isDragActive ? "Suelta el archivo aquí" : "Arrastra aquí o"}
							</Typography>
							<Button variant="contained" sx={{ mt: 2 }}>
								Adjunte el archivo
							</Button>
						</div>
						{!!get(errors, name) && (
							<Typography color="error" fontSize={"0.75rem"}>
								{get(errors, name).message}
							</Typography>
						)}
					</Grid>
				</Grid>
			)}
		</Grid>
	);
};
